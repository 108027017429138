import React from "react";
import { Container, Row } from "react-bootstrap";

const NoToken = () => {
  return (
    <Container
      as="main"
      fluid="md"
      className="d-flex flex-column align-items-center justify-content-center h-100"
    >
      <Row className="text-center">
        <h1>You don't have a valid token</h1>
      </Row>
      <Row className="text-center mt-4">
        Please use the link from your invoices page or the one that
        has been provided to you.
      </Row>
    </Container>
  );
};

export default NoToken;
