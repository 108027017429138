import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Image,
  Alert,
  Form,
  ProgressBar,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { QRCodeCanvas } from "qrcode.react";
import {
  websocketConnect,
  websocketAddObserver,
  websocketAddObserverListeners,
} from "../utils/websockets";
// import { useInterceptors } from "../hooks/useInterceptors";
import { getKeyByValue } from "../utils/getKeyByValue";
import useCountDown from "../hooks/useCountDown";
import styles from "./Collections.module.css";

const status = Object.freeze({
  CREATED: 0,
  PENDING: 1,
  ISSUES: 2,
  PICKED: 3,
  FINAL: 4, // For collection in Limbo that won't be complete
});

const collectionPoint = Object.freeze({
  A: 0, // Collection for large items. Expected to be slow
  B: 1, // Collection for small items or one large item. Expected to be fast
});

const ProgressBarComponent = () => {
  const [now, setNow] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (now === 100) {
        setNow(0);
      } else {
        setNow(now + 10);
      }
    }, 1000);
  }, [now]);

  return (
    <Col className="mt-1">
      <ProgressBar striped variant="success" now={now} visuallyHidden />
    </Col>
  );
};

const Booking = ({ data }) => {
  const [time] = useCountDown(
    `${data?.bookedDate?.split("T")[0]} ${data?.slot?.split("-")[0]}`
  );

  if (data.status === 0) {
    return (
      <Col
        md={12}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <span className="mt-2 text-center">
          You have booked a slot at{" "}
          <strong>{data.bookedDate.split("T")[0]}</strong> between{" "}
          <strong>{data.slot}.</strong>
        </span>
        <span className="text-center">
          Please scan the QR code to register for collection.
        </span>
      </Col>
    );
  }
  if (data.status === 1) {
    return (
      <Col
        md={12}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <span className="mt-2 text-center">Thank you for registering.</span>
        <span className="text-center">
          Your collection will be added to the queue in <strong>{time}</strong>
        </span>
      </Col>
    );
  }
  if (data.status === "no slots left") {
    return (
      <Col
        md={12}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <span className="mt-2 text-center">
          Unfortunately there are no available slots for your collection.
        </span>
        <span className="text-center">
          Please contact a manager for more information
        </span>
      </Col>
    );
  }
  return null;
};

const Collections = () => {
  const [token, setToken] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [receivedData, setReceivedData] = useState();
  const [receivedBooking, setReceivedBooking] = useState();
  const [wsConnectionLost, setWsConnectionLost] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [instructionValue, setInstructionValue] = useState("");
  const [submitButtonValue, setSubmitButtonValue] = useState("Submit");

  const params = useLocation();
  // const [instance] = useInterceptors(token);
  const queryClient = useQueryClient();

  useEffect(() => {
    const token = params.search.split("?token=").join("");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setToken(token);
  }, [params]);

  const { data, error, refetch } = useQuery(
    "asCollection",
    () =>
      axios
        .get(`${process.env.REACT_APP_COLLECTION_API}/collection`)
        .then((res) => res.data),
    {
      staleTime: 1000 * 60 * 10,
      keepPreviousData: true,
      enabled: token ? true : false,
    }
  );

  const booking = useQuery(
    "asBooking",
    () =>
      axios
        .get(`${process.env.REACT_APP_COLLECTION_API}/collection/booking`)
        .then((res) => res.data),
    {
      staleTime: 1000 * 60 * 10,
      keepPreviousData: true,
      enabled: token ? true : false,
    }
  );

  useEffect(() => {
    if (token) {
      websocketConnect(token, setWsConnectionLost);
    }
  }, [token]);

  useEffect(() => {
    if (invoiceId) {
      const uuid = uuidv4();

      const handleWsUpdate = (wsData) => {
        console.log(wsData);
        if (wsData.collectionId) {
          setReceivedData(wsData);
        } else if (wsData.bookingId) {
          setReceivedBooking(wsData);
        }
      };

      websocketAddObserver([invoiceId], handleWsUpdate, uuid);
      websocketAddObserverListeners([invoiceId]);
    }
  }, [invoiceId]);

  useEffect(() => {
    if (token) {
      const decodedJwt = jwt_decode(token);
      setInvoiceId(decodedJwt.invoiceId);
    }
  }, [token]);

  useEffect(() => {
    if (!receivedData) return;
    if (data?.response?.length > 0) {
      console.log(receivedData);
      if (receivedData.picker === "leaveQue") {
        console.log("test");
        queryClient.setQueryData("asCollection", { result: [] });
        return;
      }

      const updated = data.response.map((el) => {
        if (el.id === receivedData.collectionId) {
          return {
            ...el,
            collectionPoint: receivedData.collectionPoint,
            quePosition: receivedData.quePosition,
            status: receivedData.status,
            picker: receivedData.picker,
            bay: receivedData.bay,
          };
        } else return el;
      });
      queryClient.setQueryData("asCollection", { response: updated });
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedData]);

  useEffect(() => {
    if (receivedBooking) {
      if (receivedBooking.status === 0) {
        booking.refetch();
      } else if (receivedBooking.status === 1) {
        const newData = [{ ...receivedBooking }];
        queryClient.setQueryData("asBooking", { response: newData });
      } else if (receivedBooking.status === "no slots left") {
        queryClient.setQueryData("asBooking", {
          response: [{ status: "no slots left" }],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedBooking]);

  useEffect(() => {
    setTimeout(() => {
      setSubmitButtonValue("Submit");
    }, 5000);
  }, [submitButtonValue]);

  useEffect(() => {
    if (data?.response?.length > 0) {
      setInstructionValue(data.response[0].instructions);
    }
  }, [data]);

  const handleLeaveQue = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_COLLECTION_API}/collection/leave-que/${data.response[0].id}`
      );
      queryClient.setQueryData("asCollection", undefined);
      queryClient.setQueryData("asBooking", undefined);
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddInstructions = async () => {
    try {
      if (instruction.length < 1) return;
      await axios.put(
        `${process.env.REACT_APP_COLLECTION_API}/collection/add-instructions`,
        { instruction }
      );

      setInstructionValue(instruction);
      setInstruction("");
      setSubmitButtonValue("Success");
    } catch (err) {
      setSubmitButtonValue("Error");
      setInstructionValue("");
    }
  };

  const handleStatusText = (currentStatus, point, picker, bayLocation) => {
    point = getKeyByValue(collectionPoint, point);
    if (currentStatus === status.CREATED)
      return ["Thank you for waiting", "Your current position in queue:"];
    if (currentStatus === status.PENDING) {
      return [
        `Your items are being picked by ${picker}`,
        `Please wait for your items at: ${bayLocation}`,
      ];
    }
    return [
      "Your items have been picked",
      `Please collect your items from: ${bayLocation}`,
    ];
  };

  return (
    <Container as="main" fluid="md">
      {wsConnectionLost && (
        <Alert variant="danger" className={styles.wsAlert}>
          You are no longer receiving live updates, please refresh your page to
          receive live updates.
        </Alert>
      )}
      {booking.data?.response?.length === 0 && data?.response?.length === 0 && (
        <Col
          md={12}
          className="d-flex flex-column align-items-center justify-content-center mt-1"
        >
          <span className="text-center">
            Please scan the QR code to register for collection.
          </span>
        </Col>
      )}
      {booking.data?.response?.length > 0 && data?.response?.length === 0 && (
        <Booking data={booking?.data?.response[0]} />
      )}
      {error ? (
        <Col className="d-flex flex-column align-items-center justify-content-center mt-4">
          <p className="mt-2 text-primary">Error! Please try again later...</p>
        </Col>
      ) : data && data.response?.length > 0 ? (
        <Col>
          {data.response.map((el) => {
            return (
              <Col key={el.id}>
                {el.status === 1 && <ProgressBarComponent />}
              </Col>
            );
          })}
          <Table bordered responsive className="mt-1">
            <tbody>
              {data.response.map((el) => {
                return (
                  <tr className="d-flex flex-column" key={el.id}>
                    <td className="m-0 p-0 border-0 d-flex justify-content-center">
                      <h2 className="fw-bold">
                        Hi {el.forename} {el.surname}
                      </h2>
                    </td>
                    {el.status !== 2 && (
                      <>
                        <td className="m-0 p-0 border-0 d-flex flex-column align-items-center">
                          <h4 className="text-center">
                            {
                              handleStatusText(
                                el.status,
                                el.collectionPoint,
                                el.picker
                              )[0]
                            }
                          </h4>
                          <span>
                            {
                              handleStatusText(
                                el.status,
                                el.collectionPoint,
                                undefined,
                                el.bayName
                              )[1]
                            }
                          </span>
                        </td>
                        {el.status === 0 && (
                          <td className="m-0 p-0 border-0 d-flex justify-content-center mt-3 mb-3">
                            <div className={styles.progressCircleContainer}>
                              <div className={styles.outerCircle}>
                                <div className={styles.innerCircle}>
                                  <div className={styles.quePosition}>
                                    {el.status === 0
                                      ? el.quePosition + 1
                                      : getKeyByValue(
                                          collectionPoint,
                                          el.collectionPoint
                                        )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              className={`${styles.progressCircleSVG} ${styles.progressCircleAnimate}`}
                              width="160px"
                              height="160px"
                            >
                              <defs>
                                <linearGradient id="test">
                                  <stop offset="0%" stopColor="#445a3e" />
                                  <stop offset="100%" stopColor="#f4b82f" />
                                </linearGradient>
                              </defs>
                              <circle
                                cx="80"
                                cy="80"
                                r="70"
                                strokeLinecap="round"
                              />
                            </svg>
                          </td>
                        )}
                        {el.status === 0 && (
                          <td className="m-0 p-0 border-0 text-center d-flex justify-content-center">
                            <Form className="d-flex">
                              <Form.Group controlId="instruction.input">
                                <Form.Control
                                  type="text"
                                  placeholder="Special instructions..."
                                  value={instruction}
                                  onChange={(e) =>
                                    setInstruction(e.target.value)
                                  }
                                />
                              </Form.Group>
                              <Button
                                className={styles.button}
                                onClick={handleAddInstructions}
                              >
                                {submitButtonValue}
                              </Button>
                            </Form>
                          </td>
                        )}

                        <td className="m-0 p-0 border-0 d-flex justify-content-center">
                          <span>Instruction: {instructionValue}</span>
                        </td>

                        {el.status === 2 ? (
                          <td className="m-0 p-0 border-0 d-flex justify-content-center">
                            <Button
                              className={styles.leaveQueBtn}
                              onClick={handleLeaveQue}
                            >
                              Leave Queue
                            </Button>
                          </td>
                        ) : (
                          <td className="m-0 p-0 border-0 text-center">
                            <h4 className="fw-bold">
                              Invoice Id: {el.invoiceId}
                            </h4>
                          </td>
                        )}
                      </>
                    )}
                    {el.status === 2 && (
                      <>
                        <td className="m-0 p-0 border-0 text-center mt-3">
                          <h4 className="text-center">
                            {
                              handleStatusText(
                                el.status,
                                el.collectionPoint,
                                el.picker
                              )[0]
                            }
                          </h4>
                          <span>
                            {
                              handleStatusText(
                                el.status,
                                el.collectionPoint,
                                undefined,
                                el.bayName
                              )[1]
                            }
                          </span>
                          <h4 className="fw-bold">
                            Invoice Id: {el.invoiceId}
                          </h4>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Col md={12} className="d-flex justify-content-center">
            <Image src="./icons/logo.svg" alt="Logo" />
          </Col>
        </Col>
      ) : (
        <Row className="d-flex justify-content-center">
          <QRCodeCanvas
            value={token}
            size={300}
            level="L"
            className="mt-3 mb-2"
          />
          <Image src="./icons/logo.svg" alt="Logo" />
        </Row>
      )}
    </Container>
  );
};

export default Collections;
