const websocketStates = Object.freeze({
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
});

let socket = null;

let observerIds = [];
let observers = {};
let wsConnectionLost = null;

let tryWebsocketConnectionCount = 3; // Attempt to reconnect 3 times

const getWebsocketConnection = (token) => {
  console.log("Getting ws connection", new Date());
  const accessToken = token;

  if (
    accessToken &&
    (socket === null || socket.readyState >= websocketStates.CLOSING)
  ) {
    socket = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/?token=${accessToken}`
    );
    socket.addEventListener("error", function (event) {
      console.error(
        "WS has failed to connect on, please refresh the page to receive live updates"
      );
      wsConnectionLost(true);
    });
  }

  return socket;
};

export function websocketConnect(token, setWsConnectionLost) {
  wsConnectionLost = setWsConnectionLost;
  socket = getWebsocketConnection(token);

  if (socket) {
    socket.addEventListener("close", function (event) {
      // console.log("WS connection closed: " + new Date());
      if (event.code === 1006) {
        // console.log(
        //   "Error connecting to ws, getting new access token: " + event.code
        // );

        function generateRandomDelay() {
          return 5000 + Math.floor(Math.random() * 1000);
        }

        function wait() {
          return new Promise((resolve) => {
            setTimeout(resolve, generateRandomDelay());
          });
        }

        async function retryWebsocketConnection() {
          console.log("attempting reconnect");
          if (tryWebsocketConnectionCount <= 0) {
            return;
          }
          tryWebsocketConnectionCount--;
          // await getRenewedAccessToken();
          //await wait();
          wsConnectionLost(false);
          websocketConnect(token, wsConnectionLost);
        }

        retryWebsocketConnection();
      }
    });

    socket.addEventListener("open", function (event) {
      console.log("WS connection opened: " + new Date());
      if (observerIds.length > 0) {
        const msg = { type: "addCustomers", ids: observerIds };
        socket.send(JSON.stringify(msg));
      }
    });

    socket.addEventListener("message", function (event) {
      console.log("WS message received: " + new Date());
      const data = JSON.parse(event.data);
      // console.log("Socket", data);

      if (observerIds.includes(data.invoiceId)) {
        for (const [key] of Object.entries(observers[data.invoiceId])) {
          const updateFunction = observers[data.invoiceId][key];
          updateFunction(data);
        }
      }
    });
  }
}

export function websocketAddObserverListeners(userId) {
  observerIds = userId;
  socket = getWebsocketConnection();

  if (socket?.readyState === websocketStates.OPEN) {
    const msg = { type: "addCustomers", ids: observerIds };
    socket.send(JSON.stringify(msg));
    console.log("Add customers message sent: " + new Date());
  }
}

export function websocketRemoveObserverListeners() {
  socket = getWebsocketConnection();

  if (socket?.readyState === websocketStates.OPEN) {
    const msg = { type: "removeCustomers", ids: observerIds };
    socket.send(JSON.stringify(msg));
    // console.log("Remove listeners message sent: " + new Date());
  }
}

export function websocketAddObserver(invoiceId, updateFunction, uuid) {
  if (observers.hasOwnProperty(invoiceId)) {
    observers[invoiceId][uuid] = updateFunction;
  } else {
    const observerView = {};
    observerView[uuid] = updateFunction;
    observers[invoiceId] = observerView;
  }
}

export function websocketRemoveObserver(invoiceId, uuid) {
  if (
    observers.hasOwnProperty(invoiceId) &&
    Object.keys(observers[invoiceId]).length > 1
  ) {
    delete observers[invoiceId][uuid];
  } else {
    delete observers[invoiceId];
  }
}
