import { useEffect, useState } from "react";

const useCountDown = (bookedDate) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const result = timeDifference();
      setTime(result);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const timeDifference = () => {
    const currentDate = new Date();
    const savedDate = new Date(bookedDate);

    const secs = Math.floor((savedDate - currentDate) / 1000);
    if (secs <= 0) return "00:00:00";

    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor(secs / 60) % 60;
    let seconds = secs % 60;
    return [hours, minutes, seconds]
      .map((v) => ("" + v).padStart(2, "0"))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };

  return [time];
};

export default useCountDown;
